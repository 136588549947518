@mixin ngx-layout() {
  @include media-breakpoint-down(is) {
    .row {
      margin-left: -10px;
      margin-right: -10px;
    }
  }
}

ng2-smart-table {
  button, label {
    height: 35px;
  }
}

.nb-theme-dark {
  ng2-smart-table {
    input, button {
      background: #222C45!important;
      color: white!important;
    }
    button, label {
      border: none!important;
    }
  }
}

.ng2-smart-th  {
  min-width: 150px;
}

.routesPerDay {
  min-width: 250px;
}

.activateByAll {
  min-width: 200px;
}